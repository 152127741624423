@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
    font-family: "LabilGrotesk-Medium";
    src: url("/public/fonts/LabilGrotesk-Medium.otf");
}

@font-face {
    font-family: "LabilGrotesk-Regular";
    src: url("/public/fonts/LabilGrotesk-Regular.otf");
}

@layer base {
    h1 {
        @apply relative mt-0 mb-6  text-2xl sm:text-5xl font-semibold tracking-normal leading-tight normal-case text-zinc-800
    }

    h2 {
        @apply text-lg font-medium leading-6 text-gray-900
    }

    h3 {
        @apply mx-2 sm:text-xl font-normal leading-normal tracking-tight text-slate-600 text-xl
    }
}

@layer components {
    .btn_default {
        @apply mx-auto sm:mx-0 mt-4 sm:mt-2 text-slate-900 bg-primary border border-slate-900 text-sm sm:w-64 font-bold py-3 px-6 focus:outline-none rounded-full hover:bg-gray-900 hover:text-primary;
    }

    .btn_small {
        @apply mx-auto sm:mx-0 mt-4 sm:mt-2 text-slate-800 bg-primary border border-slate-900 text-sm font-bold py-2 px-6 focus:outline-none hover:bg-slate-400 rounded-full hover:bg-gray-900 hover:text-primary;
    }
}
